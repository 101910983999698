import { gql } from "@apollo/client";

export default gql`

fragment CameraEditFragment on Camera {
  id
  name
  description
  username
  password
  host
  path
  port
  protocol
  retentionDays
  motionDetectThresold
  streamingType
  codec
  codecAudio
}
`