import {gql} from "@apollo/client";

export default gql`
  fragment ContractFragment on Contract {
    id
    Customer {
      id
      initials
      name
      PaymentPref {
        id
        type
        BankAccount {
          id
          bankName
        }
        Card {
          id
          last4
        }
      }
      Contact {
        id
        email
        lastname
        firstname
        type
      }
    }
  }
`