import React, { useState } from "react"


// import { CurrencyFormat } from "components/common/Format/CurrencyFormat"
// import { DateFormat } from "components/common/Format/DateFormat"

import { URL_INVOICE_PDF } from "static/constant/backoffice/url";
import { findToken } from "helpers/User";
import { Vars } from "libs/Vars";

import { InvoiceEdit } from "components/backoffice/BILLING/Invoice/Edit/InvoiceEdit";
import { InvoiceStatus } from "../Status/InvoiceStatus";
import { gql, useMutation, useQuery } from "@apollo/client";
import invoiceListGql from "graphql/BILLING/Invoice/query/invoiceList.gql"
import { Pagination } from "components/common/Navigation/Pagination/Pagination";
import { AutoCompleteInput, DateInput, IconArrowDown, IconArrowUp, IconEdit, IconSearch, IconTrash, Modal } from "@zipou/front_tools";
import { Contract, Customer, Invoice, InvoiceListInput, InvoiceOrderDirectionEnum, InvoiceOrderEnum, InvoiceStatusEnum } from "model";
import { InvoiceAdd } from "../Add/InvoiceAdd";
import { DateFormat } from "components/common/Format/DateFormat";
import { CurrencyFormat } from "components/common/Format/CurrencyFormat";
import { InvoiceDisplay } from "../Display/InvoiceDisplay";
import { CustomerEdit } from "../../Customer/Edit/CustomerEdit";

type InvoiceListProps = {

}

export const InvoiceList = ({ }: InvoiceListProps) => {

  const [contrat, updateContrat] = useState<Contract | null>()
  const [displayDateFilter, updateDisplayDateFilter] = useState<boolean>()
  const [focusInvoiceView, updateFocusInvoiceView] = useState<Invoice>()
  const [displayCustomerEdit, updateDisplayCustomerEdit] = useState<Customer | null>()

  const [filterInput, updateFilterInput] = useState<InvoiceListInput>({
    orderField: InvoiceOrderEnum.DATE,
    orderDirection: InvoiceOrderDirectionEnum.DESC,
    status: [InvoiceStatusEnum.STATUS_PENDING, InvoiceStatusEnum.STATUS_DRAFT, InvoiceStatusEnum.STATUS_ERROR],
    contract: [],
    dateEnd: null,
    dateStart: null,
  })

  const contractListResponse = useQuery<{ list: Contract[] }>(gql`query contractList { list: contractList { id Customer { id name } } }`)

  const { data, refetch } = useQuery(invoiceListGql, {
    variables: {
      cursor: {
        page: 1,
        limit: 30
      },
      input: {
        ...filterInput,
      }
    }
  })
  const { cursor, nodes } = data?.list || {}
  const { __typename, totalPages, hasMore, ...cursorRest } = cursor || {}

  const invoiceDeleteMutation = useMutation(gql`mutation invoiceDelete($id: String!) {  invoiceDelete(id: $id) } `, {
    refetchQueries: [{
      query: invoiceListGql,
      variables: {
        cursor: cursorRest,
        input: filterInput,
      }
    }]
  })

  const onDelete = (id: string) => {
    invoiceDeleteMutation[0]({
      variables: {
        id,
      }
    })
      .then(() => console.log("OK"))
      .catch((e: any) => console.log(`${e.message}`))
  }


  const [modal, updateModal] = useState<any>(null)

  const _renderModal = () => {
    if (modal) {
      const { type, data } = modal
      switch (type) {

        case "INVOICE_EDIT":
          return <Modal display={!!modal} onClose={() => updateModal(false)} title={"Modifier une facture"}>
            <InvoiceEdit
              invoiceId={data.id}
              onDone={() => updateModal(false)}
            />
          </Modal>

        case "STATUS_FOCUS":
          return <Modal display={!!modal} onClose={() => updateModal(false)} title={"Modifier une facture"}>
            <ul className="list-group">
              {Object.values(InvoiceStatusEnum)?.map((status) => {
                const isChecked = filterInput?.status?.includes(status)
                return <li className="list-group-item">
                  <input id={status} type="checkbox" checked={isChecked} onChange={(e: any) => {
                    updateFilterInput({
                      ...filterInput,
                      status: e.target.checked ? [...(filterInput?.status || []), status] : filterInput.status.filter((el: string) => el !== status)
                    })
                  }}></input> <label htmlFor={status}><InvoiceStatus status={status} /></label>
                </li>
              })}
            </ul>
          </Modal>

        case "ADD_FOCUS":
          return <Modal display={!!modal} onClose={() => updateModal(false)}>
            <InvoiceAdd
              onDone={() => {
                const { __typename, totalPages, hasMore, ...cursorRest } = cursor
                refetch({
                  input: filterInput,
                  cursor: cursorRest,
                }).then(() => updateModal(false))
              }}
            />
          </Modal>

        case "CONTRACT_FOCUS":
          return <Modal display={!!modal} onClose={() => updateModal(false)}>
            <div className="card">
              <div className="card-header">
                <AutoCompleteInput<Contract> isError={false} onSearch={async (search) => contractListResponse?.data?.list?.filter(c => c.Customer?.name?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase())) || []} onChange={(c) => {
                  updateContrat(null)
                  updateFilterInput({
                    ...filterInput,
                    contract: [...(filterInput?.contract || []), c?.id || ""]
                  })
                }} label="Rechercher" displayValue={(contract) => contract?.Customer?.name} value={undefined} disabled={false} />
              </div>
              <div className="card-body">
                <div className="d-flex" style={{ flexWrap: "wrap" }}>
                  {[...contractListResponse?.data?.list || []]
                    ?.sort((el1, el2) => el1?.Customer?.name?.localeCompare(el2?.Customer?.name))
                    ?.map((contract) => {
                      const isChecked = filterInput?.contract?.includes(contract.id)
                      return <div className="form-check d-flex flex-row" style={{ justifyContent: "center", alignItems: "center" }}>
                        {/* <div className="custom-control custom-switch"> */}
                        <input id={contract?.id} className="" type="checkbox" checked={isChecked} onChange={(e: any) => {
                          updateFilterInput({
                            ...filterInput,
                            contract: e.target.checked ? [...(filterInput?.contract || []), contract?.id] : filterInput?.contract?.filter((el: string) => el !== contract?.id || "")
                          })
                        }} style={{ marginTop: 2 }} />
                        <label className="m-0" htmlFor={contract?.id}>
                          <span className="badge badge-sm badge-dark">{contract?.Customer?.name}</span>
                        </label>
                      </div>
                      // </div>
                    })}
                </div>
              </div>
            </div>

          </Modal>

        default:
          return <div />

      }

    }
  }

  const invoiceList = nodes
  const { api_enpoint } = Vars
  let compilTotalHT = 0;
  let compilTotalTTC = 0;
  return (<div>
    {_renderModal()}
    {!!displayCustomerEdit && <Modal display={true} title={"Modifier un client"} onClose={() => updateDisplayCustomerEdit(null)}>
      <CustomerEdit id={displayCustomerEdit?.id} onDone={() => updateDisplayCustomerEdit(null)}
      />
    </Modal>}
    {displayDateFilter && <Modal display title="Tirer par Date" onClose={() => updateDisplayDateFilter(false)}>
      <DateInput value={filterInput.dateStart && filterInput.dateStart * 1000} label="Date Début" onChange={(v, d) => {
        updateFilterInput({
          ...filterInput,
          dateStart: v / 1000
        })
      }} />

      <DateInput value={filterInput.dateEnd && filterInput.dateEnd * 1000} label="Date Fin" onChange={(v, d) => {
        updateFilterInput({
          ...filterInput,
          dateEnd: v / 1000
        })
      }} />
    </Modal>}
    {focusInvoiceView && <Modal display={!!focusInvoiceView} title="Aperçu" onClose={() => updateFocusInvoiceView(undefined)}>
      <InvoiceDisplay invoice={focusInvoiceView} />
    </Modal>}
    <table className='table table-bordered table-stripped'>
      <thead>
        <tr className="bg-dark text-white">
          <th className="bg-dark text-white">
            <button className="btn btn-sm btn-secondary" onClick={() => {
              updateFilterInput({
                ...filterInput,
                orderDirection: (filterInput.orderField === InvoiceOrderEnum.PUBLICID) ? (filterInput.orderDirection === InvoiceOrderDirectionEnum.ASC) ? InvoiceOrderDirectionEnum.DESC : InvoiceOrderDirectionEnum.ASC : InvoiceOrderDirectionEnum.DESC,
                orderField: InvoiceOrderEnum.PUBLICID,
              })
            }}>{filterInput.orderField === InvoiceOrderEnum.PUBLICID && <span>
              {(filterInput.orderDirection === InvoiceOrderDirectionEnum.ASC) ? <IconArrowUp /> : <IconArrowDown />}
            </span>}Id</button>
          </th>
          <th className="bg-dark text-white">
            <button className="btn btn-sm btn-secondary" onClick={() => {
              updateDisplayDateFilter(true)
            }}>{filterInput.orderField === InvoiceOrderEnum.DATE && <span>
              {(filterInput.orderDirection === InvoiceOrderDirectionEnum.ASC) ? <IconArrowUp /> : <IconArrowDown />}
            </span>} Date</button>
          </th>
          <th className="bg-dark text-white">
            <button className="btn btn-sm btn-secondary" onClick={() => updateModal({ type: "CONTRACT_FOCUS" })}>Client</button>
          </th>
          <th className="bg-dark text-white">
            <button className="btn btn-sm btn-secondary" onClick={() => updateModal({ type: "STATUS_FOCUS" })}>Status</button>
          </th>
          {/* <th>Total HT</th> */}
          <th className="bg-dark text-white">TTC</th>
          <th className="bg-dark text-white">Actions</th>
        </tr>
      </thead>
      <tbody>
        {invoiceList && invoiceList.map((invoice: any) => {
          const { id, publicId, date, status, totalHT, totalTTC, Contract } = invoice
          const viewUrl = URL_INVOICE_PDF.replace("${apiRoot}", api_enpoint).replace("${publicId}", publicId).replace("${accessToken}", findToken()).replace("${stamp}", `${new Date().getTime()}`)
          const classNameTr = (status === "STATUS_ERROR") ? "error" : (status === "STATUS_DRAFT") ? "warning" : ""
          compilTotalHT = compilTotalHT + totalHT
          compilTotalTTC = compilTotalTTC + totalTTC
          return (
            <tr key={id} className={classNameTr}>
              <td>{publicId}</td>
              <td>{date && <DateFormat unixTimestamp={date} short={true} />}</td>
              <td>
                <button className="btn btn-sm btn-dark" onClick={() => updateDisplayCustomerEdit(Contract?.Customer)}>
                  {Contract?.Customer?.name}
                </button>
              </td>
              <td><InvoiceStatus status={status} /></td>
              {/* <td>{totalHT && <CurrencyFormat withDecimal={false} value={totalHT} />}</td> */}
              <td>{totalTTC && <CurrencyFormat withDecimal={false} value={totalTTC} />}</td>
              <td style={{ display: "flex" }}>
                <button className="btn btn-info btn-sm" type="button" onClick={() => updateFocusInvoiceView(invoice)}>
                  <IconSearch />
                </button>
                <button style={{ marginLeft: 5 }} className="btn btn-warning btn-sm" type="button" onClick={() => { updateModal({ type: "INVOICE_EDIT", data: invoice }) }}>
                  <IconEdit />
                </button>
                <button style={{ marginLeft: 5 }} className="btn btn-sm btn-danger" type="button" onClick={() => onDelete(invoice?.id)}>
                  <IconTrash />
                </button>
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
    <div>
      <Pagination cursor={data?.list?.cursor} onChange={(page: number, limit?: number) => {
        const { __typename, totalPages, hasMore, ...cursorRest } = cursor
        refetch({
          input: filterInput,
          cursor: {
            ...cursorRest,
            page,
            limit,
          }
        })
      }} />
      <button className="btn btn-dark btn-sm" onClick={() => updateModal({ type: "ADD_FOCUS" })}>Ajouter</button>
    </div>
  </div>
  )
}