import { gql } from "@apollo/client";
import InvoiceFragment from "../fragment/InvoiceFragment";

export default gql`

  ${InvoiceFragment}

  mutation invoiceChargeWithPaymentPrefId($id: String!, $paymentPrefId: String!) {
    invoice: invoiceChargeWithPaymentPrefId(id: $id, paymentPrefId: $paymentPrefId) {
      ...InvoiceFragment
    }
  }

`