import { PaymentPrefInput } from "model"
import { useState } from "react"
import { PaymentPrefForm } from "../Form/PaymentPrefForm"
import { StripeContainer } from "../../StripeContainer"
import { useMutation } from "@apollo/client"
import paymentPrefAddGql from "graphql/BILLING/PaymentPref/mutation/paymentPrefAdd.gql"

type PaymentPrefAddProps = {
  customerId: string,
  stripePk: string,
  onDone: () => void,
}

export const PaymentPrefAdd = ({ onDone, stripePk, customerId }: PaymentPrefAddProps) => {


  const [mutate] = useMutation(paymentPrefAddGql)


  const [paymentPref, updatePaymentPref] = useState<PaymentPrefInput>()

  const onSave = () => {
    mutate({
      variables: {
        customerId,
        input: paymentPref,
      }
    })
      .then(() => {
        onDone && onDone()
        console.log("OK")
      })
      .catch((e) => {
        console.log("ERROR", e)
      })
  }

  const onChange = (pPref: PaymentPrefInput) => {
    updatePaymentPref(pPref)
  }


  return <div>
    <StripeContainer stripePk={stripePk}>
      <PaymentPrefForm paymentPref={paymentPref} onChange={onChange} />
    </StripeContainer>
    <div>
      <button className="btn btn-sm btn-dark" onClick={() => onSave()}>Enregistrer</button>
    </div>
  </div>


}