import { gql } from "@apollo/client";
import PaymentPrefFragment from "../fragment/PaymentPrefFragment";

export default gql`

  ${PaymentPrefFragment}

  query paymentPrefList($customerId: String!) {
    list: paymentPrefList(customerId: $customerId) {
      ...PaymentPrefFragment
    } 
  }


`