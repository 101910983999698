import { useQuery } from "@apollo/client"
import { BooleanInput, IconTrash } from "@zipou/front_tools"
import clusterRoleList from "graphql/HOSTING/Cluster/ClusterRole/query/clusterRoleList"
import { ClusterConfig, ClusterConfigInput, ClusterInput, ClusterRole, ClusterRoleConfig, ClusterRoleConfigInput, ClusterRoleTypeEnum } from "model"

type LoadBalancerFormProps = {
  options: any,
  clusterConfig: ClusterConfigInput,
  cluster: ClusterInput,
  onChange: (config: any) => void
}

export const LoadBalancerForm = ({ options, cluster, clusterConfig, onChange }: LoadBalancerFormProps) => {

  const { data } = useQuery<{ clusterRoleList: ClusterRole[] }>(clusterRoleList)
  const roleIdWithLoadBalancerCLient = data?.clusterRoleList
    ?.filter((el) => el.isLoadBalancerClient)
    ?.map((el) => el.id)

  const configList = options?.configList || [];

  const loadBalancerTargetRoles = cluster?.ClusterConfig?.reduce<{ [key: string]: ClusterRoleConfig[] }>((acc, clusterConfig) => {
    //For Each ClusterRoleConfig
    const roleConfigFiltered = clusterConfig?.ClusterRoleConfig?.filter(el => {
      return roleIdWithLoadBalancerCLient?.includes(el?.clusterRoleId || "")
    }) || []

    return {
      ...acc,
      [clusterConfig?.name || ""]: [...(acc[clusterConfig?.id || ""] || []), ...roleConfigFiltered]
    }

    // return [...acc, { clusterConfig: roleConfigFiltered, name: clusterConfig?.name }]
  }, {}) || {}

  return <>
    {configList?.map((config: any, index: number) => {
      return <div className="input-group" key={`domain_${index}`}>
        <span className="input-group-text"></span>
        <LoadBalancerConfig targetRoleList={loadBalancerTargetRoles} config={config} onChange={(v) => {
          onChange({
            configList: configList?.map((c: any, id: number) => {
              return (id === index) ? v : c
            }) || []
          })
        }
        } />
        <span className="input-group-text" onClick={() => {
          onChange({
            configList: configList?.filter((c: any, id: number) => {
              return (id !== index)
            })
          })
        }}>
          <IconTrash />
        </span>
      </div>
    })}
    <button className="btn btn-secondary btn-sm" onClick={() => {
      onChange({
        ...options,
        configList: [
          ...configList,
          {
            target: "",
            domain: "",
          }
        ]
      })
    }}>Ajouter</button>
  </>
}


type LoadBalancerConfigProps = {
  config: any,
  targetRoleList?: {
    [key: string]: ClusterRoleConfig[]
  },
  onChange: (el: any) => void
}

export const LoadBalancerConfig = (props: LoadBalancerConfigProps) => {
  const { config, targetRoleList, onChange } = props;
  const { domain, target, forceHttps, cacheEnabled, rewriteHttpHostHeader } = config

  // const targetValue = targetRoleList?.find(el => el?.clusterRoleId === target)?.clusterRoleId || ""

  // const targetValue = Object.values(targetRoleList).find(el => el?. === target)


  return <>
    <div className="form-control" style={{ height: '100%' }}>
      <div className="input-group">
        <span className="input-group-text">Nom</span>
        <input type="text" className="form-control" name="domain" value={domain} onChange={(e: any) => {
          onChange({
            ...config,
            [e.target.name]: e.target.value,
          })
        }} />
      </div>
      <div className="input-group">
        <span className="input-group-text">Cible</span>
        <select className="form-control" name="target" value={target || ""} onChange={(e: any) => {
          onChange({
            ...config,
            target: e.target.value,
          })
        }}>
          <option value="" disabled>Choisissez une valeur</option>
          {Object.keys(targetRoleList || {}).map((key, index) => {
            const value = targetRoleList && targetRoleList[key || ""]
            return <optgroup key={`${key}_${index}`} id={key} label={key}>
              {value?.map((v, idx) => <option key={`${v?.id}_${idx}_${index}`} value={v?.id || ""}>{v.name}</option>)}
            </optgroup>
          })}
          {/* {targetRoleList?.map((el, index: number) => {
            return <option key={`option_${el?.clusterRoleId}_${index}`} value={el?.clusterRoleId}>

              {el?.name}</option>
          })} */}
        </select>
      </div>
      <BooleanInput label="Forcer le HTTPS" id="forceHttps" errors={{}} value={forceHttps} onChange={(v) => {
        props?.onChange({
          ...config,
          forceHttps: v
        })
      }} />
      <BooleanInput label="Activer le cache" id="cacheEnabled" errors={{}} value={cacheEnabled} onChange={(v) => {
        props?.onChange({
          ...config,
          cacheEnabled: v
        })
      }} />
      <BooleanInput label="Réécrire le Header Http Host" id="rewriteHttpHostHeader" errors={{}} value={rewriteHttpHostHeader} onChange={(v) => {
        props?.onChange({
          ...config,
          rewriteHttpHostHeader: v
        })
      }} />

    </div>
  </>
}