import { useMutation, useQuery } from "@apollo/client"
import { IconEdit, IconTrash, Modal } from "@zipou/front_tools"
import paymentPrefListGql from "graphql/BILLING/PaymentPref/query/paymentPrefList.gql"
import { PaymentPref } from "model"
import { useState } from "react"
import { PaymentPrefAdd } from "../Add/PaymentPrefAdd"
import paymentPrefDeleteGql from "graphql/BILLING/PaymentPref/mutation/paymentPrefDelete.gql"

type PaymentPrefListProps = {
  customerId: string,
  stripePk: string,
}

export const PaymentPrefList = ({ stripePk, customerId }: PaymentPrefListProps) => {

  const [focusAdd, updateFocusAdd] = useState<boolean>(false)

  const { data, refetch } = useQuery<{ list: PaymentPref[] }, { customerId: String }>(paymentPrefListGql, {
    variables: {
      customerId,
    },
    skip: !customerId
  })

  const [deleteMutate] = useMutation(paymentPrefDeleteGql)


  const onDelete = (id: string) => {
    deleteMutate({
      variables: {
        id
      },
      refetchQueries: [{
        query: paymentPrefListGql,
        variables: {
          customerId,
        }
      }]
    })
  }

  const paymentPrefList = data?.list

  return <div>
    <Modal display={focusAdd} onClose={() => updateFocusAdd(false)}>
      <PaymentPrefAdd customerId={customerId} stripePk={stripePk} onDone={() => {
        updateFocusAdd(false)
        refetch({
          customerId
        })
      }} />
    </Modal>
    <table className="table table-bordered table-striped">
      <tbody>
        {paymentPrefList?.map((ppref, index) => {
          return <tr key={`ppref_${ppref?.id}_${index}`}>
            <td>
              {ppref?.type}
            </td>
            <td>
              {ppref?.BankAccount?.bankName || ""}
              {ppref?.Card?.last4 || ""}
            </td>
            <td>
              {/* <button className="btn btn-warning btn-sm ml-1">
                <IconEdit />
              </button> */}
              <button className="btn btn-danger btn-sm ml-1" onClick={() => onDelete(ppref?.id)}>
                <IconTrash />
              </button>
            </td>
          </tr>
        })}
      </tbody>
    </table>
    <div>
      <button className="btn btn-sm btn-dark" onClick={() => updateFocusAdd(true)}>Ajouter</button>
    </div>
  </div>
}