import { InvoiceList } from "components/backoffice/BILLING/Invoice/List/InvoiceList"
import BootstrapBreadcrumb from "react-bootstrap/Breadcrumb"


export const InvoiceListRoute = (props: any) => {

  return <div className="">

    <BootstrapBreadcrumb>
      <BootstrapBreadcrumb.Item active>Facturation</BootstrapBreadcrumb.Item>
      <BootstrapBreadcrumb.Item active>Liste des Factures</BootstrapBreadcrumb.Item>
    </BootstrapBreadcrumb>

    <div className="">
      <h1>Liste des factures</h1>
      <InvoiceList />
    </div>
  </div>



}