import { useMutation, useQuery } from "@apollo/client"
import customerUpdateGql from "graphql/BILLING/Customer/mutation/customerUpdate.gql"
import customerGetGql from "graphql/BILLING/Customer/query/customerGet.gql"
import { formatErrorResponseForJoi } from "helpers/Apollo"
import React, { useState } from "react"
import { CustomerForm } from "../Form/CustomerForm"
import { useParams } from "react-router"
import { Customer, CustomerInput, PaymentPrefTypeEnum } from "model"

type CustomerEditProps = {
  id: string,
  onDone: () => void,
}


export const CustomerEdit = ({ id, onDone }: CustomerEditProps) => {

  const [customer, updateCustomer] = useState<CustomerInput>()
  const [errors, updateErrors] = useState<any>(null)

  const params = useParams()

  const { data } = useQuery<{ customer: Customer }>(customerGetGql, {
    variables: {
      id,
    },
    // skip: !props?.id,
    onCompleted: d => {
      const { id, __typename, Company, PaymentPref, ...input } = d.customer || {}
      updateCustomer(input)
    }
  })

  const [mutate] = useMutation<{ customer: Customer }, { id: string, input?: CustomerInput | null }>(customerUpdateGql)

  const onReset = () => {
    if (data?.customer) {
      const { id, __typename, Company, PaymentPref, ...input } = data?.customer
      if (input) {
        updateCustomer({
          ...input,
        })
      }
      onDone && onDone()
    }

  }

  const onSave = (e: any) => {
    e.preventDefault();


    mutate({
      variables: {
        id,
        input: customer,
        // input: {
        //   ...input,
        //   name: input?.name || "",
        //   name: input?.ini || "",
        //   Address: {
        //     address1: Address?.address1,
        //     address2: Address?.address2,
        //     zip: Address?.zip,
        //     town: Address?.town,
        //   },
        //   PaymentPref: PaymentPref?.map((pPref) => ({
        //     id: pPref?.id,
        //     type: pPref?.type,
        //     ...((pPref?.BankAccount && pPref?.type === PaymentPrefTypeEnum.TYPE_DEBIT) ? {
        //       BankAccount: {
        //         stripePaymentMethodId: pPref?.BankAccount?.stripePaymentMethodId || "",
        //         bankName: pPref?.BankAccount?.bankName || "",
        //       }
        //     } : {})
        //   })),
        //   // Contact: input?.Contact?.map((contact: any) => {
        //   //   const { id, __typename, ...rest } = contact
        //   //   return rest
        //   // })
        // },
      }
    })
      .then(() => {
        onDone && onDone()
      })
      .catch((e: any) => {
        updateErrors(formatErrorResponseForJoi(e))
      })

  }


  return <div>
    <form onSubmit={onSave}>
      {!!customer && <CustomerForm stripePk={data?.customer?.Company?.stripePk || ""} errors={errors} customer={customer} onChange={(customer: any) => updateCustomer(customer)} />}
      <div>
        <button className="btn btn-sm btn-dark" type="submit">Enregistrer</button>
        <button className="btn btn-sm btn-danger" type="button" onClick={onReset} style={{ marginLeft: 5 }}>Annuler</button>
      </div>
    </form>
  </div>

}