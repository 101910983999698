import { BooleanInput, IconTrash, NumberInput, TextInput } from "@zipou/front_tools"
import { CameraInput, VideoConfigInput } from "model"
import { CameraForm } from "../../Camera/Form/CameraForm"

type VideoConfigFormProps = {
  videoConfig: VideoConfigInput,
  errors: any,
  onChange: (videoConfig: VideoConfigInput) => void,
}

export const VideoConfigForm = ({ videoConfig, errors, onChange }: VideoConfigFormProps) => {


  const cameraList: CameraInput[] = videoConfig?.CameraList || []

  return <div>
    <TextInput id="name" value={videoConfig?.name || ""} errors={errors} label="Nom" onChange={(v) => {
      onChange({
        ...videoConfig,
        name: v,
      })
    }} />
    <TextInput id="storageDir" value={videoConfig?.storageDir || ""} errors={errors} label="Dossier Stockage" onChange={(v) => {
      onChange({
        ...videoConfig,
        storageDir: v,
      })
    }} />

    <BooleanInput id="mouvementDetectionEnableResolutionConversion" value={videoConfig?.mouvementDetectionEnableResolutionConversion === true ? true : false} errors={errors} label="Convertir Resolution" onChange={(v) => {
      onChange({
        ...videoConfig,
        mouvementDetectionEnableResolutionConversion: v,
      })
    }} />

    <NumberInput id="mouvementDetectionNbFrameToSkip" value={`${videoConfig?.mouvementDetectionNbFrameToSkip}` || ""} errors={errors} label="Frame to Skip" onChange={(_, v) => {
      onChange({
        ...videoConfig,
        mouvementDetectionNbFrameToSkip: Number(v),
      })
    }} />
    <div className="card m-1">
      <div className="card-header">
        Liste des caméras
      </div>
      <div className="card-body">
        {cameraList?.map((camera, index) => {
          return <div className="input-group" key={`camera_${camera?.id || ""}_${index}`}>
            <span className="input-group-text">Camera {index + 1}</span>
            <div className="form-control" style={{ height: "100%" }}>
              <CameraForm
                errors={errors}
                camera={camera}
                onChange={(c) => {
                  onChange({
                    ...videoConfig,
                    CameraList: videoConfig?.CameraList?.map((cam, idx) => idx === index ? c : cam)
                  })
                }} />
            </div>
            <span className="input-group-text" onClick={() => {
              onChange({
                ...videoConfig,
                CameraList: [...(videoConfig?.CameraList || []), {}]
              })
            }}>

              <IconTrash />
            </span>
          </div>
        })}
      </div>
      <div className="card-footer">
        <button className="btn btn-sm btn-dark" onClick={() => {
          onChange({
            ...videoConfig,
            CameraList: [...(videoConfig?.CameraList || []), {}]
          })
        }}>Ajouter une caméra</button>

      </div>
    </div>
  </div>
}