import { ItemEdit } from "components/backoffice/BILLING/Item/Edit/ItemEdit"
import { IconEdit, IconSearch, IconTrash, Modal, PriceFormat } from "@zipou/front_tools"
import { useMutation, useQuery } from "@apollo/client"
import itemListGql from "graphql/BILLING/Item/query/itemList.gql"
import { Item } from "model"
import { useState } from "react"
import itemDeleteGql from "graphql/BILLING/Item/mutation/itemDelete.gql"
import { ItemAdd } from "../Add/ItemAdd"
import { ItemView } from "../View/ItemView"

type ItemListProps = {

}

export const ItemList = ({ }: ItemListProps) => {

  const { data, refetch } = useQuery<{ list: Item[] }>(itemListGql)
  const [focusAdd, updateFocusAdd] = useState(false)
  const [focusEdit, updateFocusEdit] = useState<Item | null>()
  const [focusView, updateFocusView] = useState<Item | null>()

  const [mutate] = useMutation(itemDeleteGql, {
    refetchQueries: [{
      query: itemListGql
    }]
  })

  const onDelete = (id: string) => {
    mutate({
      variables: {
        id,
      }
    })
      .then(() => console.log("OK"))
      .catch((e) => console.log("EEEE", e))
  }

  const itemList = data?.list
  return (<div>
    {focusAdd && <Modal title="Ajouter" display onClose={() => updateFocusAdd(false)}>
      <ItemAdd onDone={() => {
        updateFocusAdd(false);
        refetch()
      }} />
    </Modal>}
    {focusEdit && <Modal title="Editer" display onClose={() => updateFocusEdit(null)}>
      <ItemEdit id={focusEdit?.id} onDone={() => {
        updateFocusEdit(null);
        refetch()
      }} />
    </Modal>}
    {focusView && <Modal title="Voir" display onClose={() => updateFocusView(null)}>
      <ItemView item={focusView} />
    </Modal>}
    <table className='table table-striped table-bordered'>
      <thead>
        <tr className="bg-dark text-white">
          <th className="bg-dark text-white">Nom</th>
          <th className="bg-dark text-white">Usage</th>
          <th className="bg-dark text-white">Prix</th>
          <th className="bg-dark text-white">TVA</th>
          <th className="bg-dark text-white">Actions</th>
        </tr>
      </thead>
      <tbody>
        {itemList?.map((item, index) => {
          const { id, label, price, OrderLine } = item

          const usageCount = OrderLine?.reduce((acc, orderLine) => {
            return acc + orderLine?.quantity
          }, 0) || 0

          return (
            <tr key={`itemList_${id}_${index}`}>
              <td>{label}</td>
              <td>
                {usageCount > 0 ? <span className="badge badge-warning">{usageCount || 0}</span> : <span className="badge badge-dark">0</span>}
              </td>
              <td>
                <PriceFormat value={price} />
              </td>
              <td>
                {item?.vat}
              </td>
              <td style={{ whiteSpace: "nowrap" }}>
                <button className="btn btn-sm btn-warning ml-1" type="button" onClick={() => updateFocusView(item)}>
                  <IconSearch />
                </button>
                <button className="btn btn-sm btn-info ml-1" type="button" onClick={() => updateFocusEdit(item)}>
                  <IconEdit />
                </button>
                <button className="btn btn-sm btn-danger ml-1" type="button" onClick={() => onDelete(item?.id)}>
                  <IconTrash />
                </button>
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
    <div>
      <button className="btn btn-sm btn-dark" onClick={() => updateFocusAdd(true)}>Ajouter</button>
    </div>
  </div>
  )
  // }
}