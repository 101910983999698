import { Modal } from "@zipou/front_tools"
import { findToken } from "helpers/User"
import { Vars } from "libs/Vars"
import { Invoice, InvoiceStatusEnum } from "model"
import { useEffect, useState } from "react"
import { URL_INVOICE_PDF } from "static/constant/backoffice/url"
import { InvoiceCharge } from "../Charge/InvoiceCharge"

type InvoiceDisplayProps = {
  invoice: Invoice,
}

export const InvoiceDisplay = ({ invoice }: InvoiceDisplayProps) => {

  const [serial, updateSerial] = useState<any>()
  const [focusCharge, updateFocusCharge] = useState<boolean>(false)
  const { api_enpoint } = Vars

  useEffect(() => {
    if (!serial) updateSerial(new Date().getTime())
  }, [])

  const viewUrl = URL_INVOICE_PDF.replace("${apiRoot}", api_enpoint).replace("${publicId}", invoice?.publicId).replace("${accessToken}", findToken()).replace("${stamp}", `${serial}`)

  const isInvoiceChargeable = invoice?.status === InvoiceStatusEnum.STATUS_PENDING

  return <div className="">
    <Modal display={focusCharge} size="xl" title="Charger la facture" onClose={() => updateFocusCharge(false)}>
      <InvoiceCharge invoice={invoice} onDone={() => updateFocusCharge(false)} />
    </Modal>
    <div className="card-body">
      <iframe src={viewUrl} style={{ width: '100%', height: '90vh', border: 0 }}>
      </iframe>
    </div>
    <div className="card-footer">
      {isInvoiceChargeable && <button className="btn btn-warning btn-sm" onClick={() => updateFocusCharge(true)}>Charger la facture</button>}
    </div>
  </div>
}