import { useMutation, useQuery } from "@apollo/client"
import { IconLoading, PriceFormat } from "@zipou/front_tools"
import invoiceChargeWithPaymentPrefIdGql from "graphql/BILLING/Invoice/mutation/invoiceChargeWithPaymentPrefId.gql"
import paymentPrefListGql from "graphql/BILLING/PaymentPref/query/paymentPrefList.gql"
import { Invoice, InvoiceStatusEnum, PaymentPref } from "model"
import { useState } from "react"

type InvoiceChargeProps = {
  invoice: Invoice,
  onDone: () => void,
}

export const InvoiceCharge = ({ invoice, onDone }: InvoiceChargeProps) => {

  const customerId = invoice?.Contract?.Customer?.id

  const [mutate, { loading }] = useMutation(invoiceChargeWithPaymentPrefIdGql)
  const [paymentPrefId, updatePaymentPrefId] = useState<string>()

  const { data } = useQuery<{ list: PaymentPref[] }>(paymentPrefListGql, {
    variables: {
      customerId,
    },
    skip: !customerId
  })

  const onCharge = () => {
    mutate({
      variables: {
        id: invoice?.id,
        paymentPrefId,
      }
    })
      .then(() => {
        onDone && onDone()
      })
      .catch((e) => {
        console.log("e", e)
      })
  }

  const isChargeable = !!paymentPrefId && invoice.status === InvoiceStatusEnum.STATUS_PENDING

  return <div>
    <table className="table table-striped table-bordered">
      <tbody>
        <tr>
          <th>Montant TTC</th>
          <td>
            <PriceFormat value={invoice?.totalTTC} /></td>
        </tr>
      </tbody>
    </table >

    <div className="input-group">
      <span className="input-group-text">Pref Paiement</span>
      <select className="form-control" onChange={(e) => updatePaymentPrefId(e.target.value)}>
        <option value="">Choisissez une valeur</option>
        {data?.list?.map(ppref => {
          return <option key={ppref?.id} value={ppref?.id}>{ppref?.BankAccount?.bankName} - V{ppref?.version || "0"}</option>
        })}
      </select>
    </div>
    <div className="input-group">
      <button className="btn btn-sm btn-danger" disabled={!isChargeable || loading} onClick={() => onCharge()}>
        {loading && <span className="mr-1">
          <IconLoading />
        </span>}
        Charger
      </button>
    </div>


  </div >

}