import { gql } from "@apollo/client";
import PaymentPrefFragment from "../fragment/PaymentPrefFragment";

export default gql`

${PaymentPrefFragment}

mutation paymentPrefAdd($customerId: String!, $input: PaymentPrefInput!) {
  paymentPref: paymentPrefAdd(customerId: $customerId, input: $input) {
    ...PaymentPrefFragment
  }
}


`