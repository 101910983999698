import {useState} from "react"
import {useMutation} from "@apollo/client"

import {CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements, CardElement} from '@stripe/react-stripe-js';


import cardAddMutation from "graphql/BILLING/Card/mutation/cardAdd"
import cardListQuery from "graphql/BILLING/Card/query/cardList"

import "./style.scss"

type CardFormProps = {
  card?: any,
  onDone: () => void,
}

export const CardForm = (props: CardFormProps) => {

  const {onDone} = props

  const stripe = useStripe();
  const elements = useElements();

  const [error, updateError] = useState(null);
  const [loading, updateLoading] = useState<boolean>(false);

  const [addCardMutation] = useMutation(cardAddMutation, {
    refetchQueries: [
      {
        query: cardListQuery,
      }
    ]
  });

  const _handleSubmit = async (e: any) => {
    e.preventDefault();
    updateLoading(true)
    // elements.create('card')
    // this.props.stripe.handleCardPayment('pi_1ESsLUIMBpRdiPP0PUdWsr6s_secret_81GpwOea9bBzSj1TUIaMhNdzR')

    if (!stripe || !elements) return;

    //@ts-ignore
    stripe && stripe.createPaymentMethod({
      type: "card",
      /** @ts-ignore */
      card: elements.getElement(CardNumberElement),
      /** @ts-ignore */
      card: elements.getElement(CardExpiryElement),
      /** @ts-ignore */
      card: elements.getElement(CardCvcElement),
    })
      .then((stripeResponse: any) => {
        console.log({stripeResponse}) // eslint-disable-line no-console
        const {paymentMethod} = stripeResponse
        // .then(paymentMethod => {
        console.log('Received Stripe PaymentMethod:', paymentMethod);// eslint-disable-line no-console
        return addCardMutation({
          variables: {
            stripeId: paymentMethod.id
          }
        })
      })
      .then(() => onDone && onDone())
      .catch((error: any) => {
        console.log({error})// eslint-disable-line no-console
        updateError(error)
        updateLoading(false)
      })
  }

  // const {error, cardNumber, expMonth, expYear, cvc, loading} = this.state

  const canSubmit = !loading
  return (
    <form onSubmit={_handleSubmit}>
      <div className="card-item">
        {error && <div className='error-container'>
          Oups, ces informations ne sont pas correctes...
        </div>}
        <div className="card-number col-12">
          <div className="input-group">
            <div className="input-group-text">Carte</div>
            <CardNumberElement className="stripe-cardnumber-element form-control" />
          </div>
        </div>

        <div className="card-expiration col-12">
          <div className="">
            <div className="input-group">
              <div className="input-group-text">Expiration</div>
              <CardExpiryElement className="stripe-cardexpiry-element form-control" />
            </div>
          </div>
          <div className="">
            <div className="input-group">
              <div className="input-group-text">CVC</div>
              <CardCvcElement className="stripe-cardcvc-element form-control" />
            </div>
          </div>
        </div>

        <div className="card-valid-action">
          <button className='' disabled={!canSubmit} type="submit" onClick={_handleSubmit}>
            {loading && <span className="spinner"> </span>}
            {` Valider`}
          </button>
        </div>
      </div>
    </form>
  )
}

// export const CardForm = CardFormComponent