import { CustomerList } from "components/backoffice/BILLING/Customer/List/CustomerList"
import { Link, useNavigate } from "react-router-dom"
import { URL_BILLING_CUSTOMER_LIST } from "static/constant/backoffice/url"
import BootstrapBreadcrumb from "react-bootstrap/Breadcrumb"


export const CustomerListRoute = (props: any) => {

  const match = props?.match;
  const params = match?.params
  const id = params?.id

  const navigateHook = useNavigate()

  const navigate = (target: string) => {
    navigateHook(`/manager${target}`)
  }

  return <div>


    <BootstrapBreadcrumb>
      <BootstrapBreadcrumb.Item active>Facturation</BootstrapBreadcrumb.Item>
      <BootstrapBreadcrumb.Item>
        <Link to={"/manager" + URL_BILLING_CUSTOMER_LIST}>
          Liste des Clients
        </Link>
      </BootstrapBreadcrumb.Item>
    </BootstrapBreadcrumb>

    <div className="card">
      <div className="card-header">
        Liste des clients
      </div>
      <div className="card-body">
        <CustomerList />
      </div>
    </div>
  </div>
}