import React, { useState } from "react"
import { YamlEditor } from "components/backoffice/Utils/YamlEditor/YamlEditor"
import { Modal } from "@zipou/front_tools"


type PrometheusFormProps = {
  onChange: (datas: any) => void,
  options: any,
}

export const PrometheusForm = (props: PrometheusFormProps) => {

  const [showPrometheusModal, updateShowPrometheusModal] = useState(false)
  const [showAlertModal, updateShowAlertModal] = useState(false)

  const { onChange, options } = props;

  const { content = "", alertContent = "", prometheusContent = "" } = options || {}

  return <div>
    <Modal display={showPrometheusModal} onClose={() => updateShowPrometheusModal(false)} title={"prometheus.yml"}>
      <YamlEditor content={prometheusContent || content} onChange={(c) => {
        onChange({
          ...options,
          //Legacy
          content: c,
          prometheusContent: c,
        })
        updateShowPrometheusModal(false)
      }} />
    </Modal>
    <Modal display={showAlertModal} onClose={() => updateShowAlertModal(false)} title={"alert.rules.yml"}>
      <YamlEditor content={alertContent} onChange={(c) => {
        onChange({
          ...options,
          alertContent: c,
        })
        updateShowAlertModal(false)
      }} />
    </Modal>
    <div className="input-group">
      <span className="input-group-text">prometheus.yml</span>
      <div className="form-control" style={{ height: "100%" }}>
        <button className="btn btn-sm btn-dark" onClick={() => updateShowPrometheusModal(!showPrometheusModal)}>Edit</button>
      </div>
    </div>
    <div className="input-group">
      <span className="input-group-text">alert.rules.yml</span>
      <div className="form-control" style={{ height: "100%" }}>
        <button className="btn btn-sm btn-dark" onClick={() => updateShowAlertModal(!showAlertModal)}>Edit</button>
      </div>
    </div>
  </div>
}