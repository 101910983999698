import { useQuery } from "@apollo/client"
import clusterRoleGet from "graphql/HOSTING/Cluster/ClusterRole/query/clusterRoleGet"
import { ClusterRole } from "model"

type ClusterRoleBadgeProps = {
  id: string,
}

export const ClusterRoleBadge = ({ id }: ClusterRoleBadgeProps) => {

  const { data } = useQuery<{ clusterRole: ClusterRole }>(clusterRoleGet, {
    variables: {
      id,
    },
    skip: !id,
  })

  return <div>
    <span className="badge badge-sm badge-dark">{data?.clusterRole?.type}</span>
  </div>


}