import { CustomerView } from "components/backoffice/BILLING/Customer/View/CustomerView"
import { Link, useParams } from "react-router-dom"
import BootstrapBreadcrumb from "react-bootstrap/Breadcrumb"
import { URL_BILLING_CUSTOMER_LIST } from "static/constant/backoffice/url"


export const CustomerViewRoute = (props: any) => {

  const { id } = useParams<{ id: string }>()

  return <div>

    <BootstrapBreadcrumb>
      <BootstrapBreadcrumb.Item active>Facturation</BootstrapBreadcrumb.Item>
      <BootstrapBreadcrumb.Item>
        <Link to={"/manager" + URL_BILLING_CUSTOMER_LIST}>
        Liste des Clients
        </Link>
      </BootstrapBreadcrumb.Item>
      <BootstrapBreadcrumb.Item active>Editer</BootstrapBreadcrumb.Item>
    </BootstrapBreadcrumb>

    {/* <BootstrapBreadcrumb>
      <BootstrapBreadcrumb.Item active>Facturation</BootstrapBreadcrumb.Item>
      <BootstrapBreadcrumb.Item active>Liste des Clients</BootstrapBreadcrumb.Item>
      <BootstrapBreadcrumb.Item >Liste des Clients</BootstrapBreadcrumb.Item>
    </BootstrapBreadcrumb> */}


    <CustomerView customerId={id || ""} />
  </div>
}