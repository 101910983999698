import { useState } from "react"
import { IbanElement, useElements, useStripe } from '@stripe/react-stripe-js';

import "./style.scss"
import { BankAccountInput } from "model";

export type BankAccountFormProps = {
  bankAccount: BankAccountInput,
  onChange: ({ stripePaymentMethodId, bankName, email }: { stripePaymentMethodId: string, bankName: string, email: string }) => void,
}

export const BankAccountForm = (props: BankAccountFormProps) => {

  const [stripePaymentMethodId, updateStripePaymentMethodId] = useState<any>(props?.bankAccount?.stripePaymentMethodId || "")
  const [bankName, updateBankName] = useState<any>(props?.bankAccount?.bankName || "")
  const [email, updateEmail] = useState<any>(props?.bankAccount?.email || "")

  const stripe = useStripe();
  const elements = useElements();

  const onIbanChange = (e: any) => {
    console.log('e', e)
    if (e.complete) {
      console.log("COMPLETE")
      createPaymentMethod()
    }
  }

  const createPaymentMethod = () => {

    if (!stripe || !elements) return;

    const element = elements.getElement(IbanElement);
    (stripe && element) && stripe.createPaymentMethod({
      type: 'sepa_debit',
      sepa_debit: element,
      billing_details: {
        name: bankName,
        email,
      }
    })
      .then((stripeResponse) => {
        console.log({ stripeResponse }) // eslint-disable-line no-console
        const { paymentMethod } = stripeResponse
        console.log('Received Stripe PaymentMethod:', paymentMethod);// eslint-disable-line no-console
        updateStripePaymentMethodId(paymentMethod?.id)
        onChange(paymentMethod?.id || "", bankName, email)

      })
      // .then(() => onDone && onDone())
      .catch((error: any) => {
        console.log({ error })// eslint-disable-line no-console
        // updateError(error)
        // updateLoading(false)
      })
  }


  const onChange = (stripePaymentMethodId: string, bankName: string, email: string) => {
    if (bankName && stripePaymentMethodId) {
      props?.onChange({
        stripePaymentMethodId,
        bankName,
        email,
      })
    }
  }

  // const createStripeSource = () => {
  //   console.log(`Creating stripe source`)
  //   if (!stripe || !elements) return;
  //   //@ts-ignore
  //   stripe.createSource(elements.getElement(IbanElement), {
  //     type: 'sepa_debit',
  //     currency: 'eur',
  //     owner: {
  //       name: bankName,
  //     },
  //   })
  //     .then((stripeResponse: any) => {
  //       console.log(`stripeResponse`, stripeResponse)

  //       const {source, } = stripeResponse
  //       // const sepa_debit = source?.sepa_debit
  //       const sourceId = source.id
  //       updateStripeSource(sourceId)
  //       onChange(sourceId, bankName)
  //     })
  //     .catch((error: any) => {
  //       console.log({error}) //eslint-disable-line
  //     })
  // }

  const onBankNameChange = (e: any) => {
    updateBankName(e.target.value)
    onChange(stripePaymentMethodId, e.target.value, email)
  }

  const onEmailChange = (e: any) => {
    updateEmail(e.target.value)
    onChange(stripePaymentMethodId, bankName, e.target.value)
  }

  const hasBankName = !!bankName
  const hasEmail = !!email

  const ibanIsDisable = !(hasEmail && hasBankName)

  const hasStripeSource = !!stripePaymentMethodId

  return (
    <div className="bank-account-item">
      <div className="account-name col-12">
        <div className="input-group">
          <div className="input-group-text">Nom du compte</div>
          <input type="text" name="bankName" value={bankName} className="form-control" placeholder="Nom du compte" onChange={onBankNameChange} />
        </div>
        <div className="input-group">
          <div className="input-group-text">Email</div>
          <input type="text" name="email" value={email} className="form-control" placeholder="Email" onChange={onEmailChange} />
        </div>
      </div>
      {!hasStripeSource && <div className="account-number col-12">
        <div className="input-group">
          <div className="input-group-text">IBAN</div>
          <IbanElement className="stripe-iban-element form-control" options={{
            disabled: ibanIsDisable,
            supportedCountries: ['SEPA'],
            // Elements can use a placeholder as an example IBAN that reflects
            // the IBAN format of your customer's country. If you know your
            // customer's country, we recommend that you pass it to the Element as the
            // placeholderCountry.
            placeholderCountry: 'FR',
            // style: IBAN_STYLE,
          }}
            onChange={onIbanChange} />
        </div>
      </div>}
      <div className="account-name col-12">
        {hasStripeSource && <div className="alert alert-warning">Vous ne pouvez pas modifier un IBAN déjà saisi</div>}
      </div>

      {/* <span className="icon-check"></span> */}
    </div>
  )
}

