import { IconTrash, TextInput } from "@zipou/front_tools"
import { CustomerInput, PaymentPref } from "model"

import { AddressForm } from "../../Address/Form/AddressForm"
import { ContactForm } from "../../Contact/Form/ContactForm"

type CustomerFormProps = {
  customer?: CustomerInput,
  stripePk: string,
  errors?: any,
  onChange: ((customer: any) => void)
}

export const CustomerForm = ({ stripePk, customer, errors, onChange }: CustomerFormProps) => {


  const addPprefDisaled = customer?.PaymentPref && customer?.PaymentPref?.reduce((acc: number, cur: any) => {
    if (cur.id === null) return acc + 1
    return acc
  }, 0) >= 1

  return <div>
    <div>
      <TextInput label="Name" id="name" errors={errors} value={customer?.name || ""} onChange={(v: string) => {
        onChange({
          ...customer,
          name: v,
        })
      }} />
      <TextInput label="Initiales" id="initials" errors={errors} value={customer?.initials || ""} onChange={(v: string) => {
        onChange({
          ...customer,
          initials: v,
        })
      }} />
      <div className="input-group">
        <span className="input-group-text">Adresse</span>
        <div className="form-control" style={{ height: "100%" }}>
          <AddressForm address={customer?.Address} errors={errors?.Address} onChange={(address: any) => {

            onChange({
              ...customer,
              Address: address,
            })
          }} />
        </div>
        {errors?.Address && <span className="input-group-text">
          <span className="icon-warning-sign"></span>
        </span>}

      </div>
      <div className="input-group">
        <span className="input-group-text">Contact</span>
        <div className="form-control" style={{ height: "100%" }}>

          {customer?.Contact?.map((contact, index: number) => <div key={`contact_${contact?.id}`} className="input-group">
            <span className="input-group-text">Contact</span>
            <div className="form-control" style={{ height: "100%" }}>
              <ContactForm errors={errors?.contact} contact={contact} onChange={(contact: any) => {
                onChange({
                  ...customer,
                  Contact: customer?.Contact?.map((el: any, idx: number) => {
                    return idx === index ? contact : el
                  })
                })
              }} />
            </div>
            <span className="input-group-text" onClick={() => {
              onChange({
                ...customer,
                Contact: customer?.Contact?.filter((el: any, idx: number) => idx !== index)
              })
            }}>
              <IconTrash />
            </span>

          </div>)}

          <button className="btn btn-sm btn-dark" type="button" onClick={() => {
            onChange({
              ...customer,
              Contact: [
                ...(customer?.Contact || []),
                {
                  title: null,
                  firstname: null,
                }
              ]
            })
          }}>Ajouter</button>
        </div>
      </div>
      {/* <div className="input-group">
        <span className="input-group-text">Préf de Paiement</span>
        <div className="form-control" style={{ height: "100%" }}>
          {stripePk && <StripeContainer stripePk={stripePk}>

            {customer?.PaymentPref?.map((paymentPref, index: number) => <div key={`paymentPref_${paymentPref?.id}`} className="input-group">
              <span className="input-group-text">Pref</span>
              <div className="form-control" style={{ height: "100%" }}>
                <PaymentPrefForm key={`ppref_${index}`} errors={errors?.contact} paymentPref={paymentPref} onChange={(paymentPref: any) => {
                  onChange({
                    ...customer,
                    PaymentPref: customer?.PaymentPref?.map((el: any, idx: number) => {
                      return idx === index ? paymentPref : el
                    })
                  })
                }} />
              </div>
              <span className="input-group-text" onClick={() => {
                onChange({
                  ...customer,
                  PaymentPref: customer?.PaymentPref?.filter((el: any, idx: number) => idx !== index)
                })
              }}>
                <IconTrash />
              </span>

            </div>)}·
          </StripeContainer>}

          <button disabled={addPprefDisaled === true} className="btn btn-sm btn-dark" type="button" onClick={() => {
            onChange({
              ...customer,
              PaymentPref: [
                ...(customer?.PaymentPref || []),
                {
                  id: null,
                  type: null,
                }
              ]
            })
          }}>Ajouter</button>
        </div>
      </div> */}
    </div>
  </div>
}