import { CameraListModule } from "@zipou/video_front"

type CameraListModuleDisplayProps = {
  videoConfigId: string,
  token: string,
}

export const CameraListModuleDisplay = ({ videoConfigId, token }: CameraListModuleDisplayProps) => {

  const env = process.env.NODE_ENV
  console.log("ENV", env)

  const moduleConfig = env === "development" ? {
    url: "http://localhost:4001",
    wsUrl: "ws://localhost:4001",
  } : {
    url: "https://www.box4b.fr",
    wsUrl: "wss://www.box4b.fr/subscriptions",
  }


  return <div>
    <CameraListModule videoConfigId={videoConfigId} token={token}
      moduleConfig={moduleConfig}
    />
  </div>

}